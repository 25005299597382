<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Configuración informes</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Configuración Informes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-gradient-primary"
                        @click="createInforme()"
                        v-if="
                          $store.getters.can('hidrocarburos.configuracionInformes.create')
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>

                      <button
                        type="button"
                        class="btn bg-gradient-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-gradient-purple"
                        @click="limpiarFiltros()"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead class="bg-gradient-dark">
                    <tr>
                      <th class="text-center p-3">
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center p-3">
                        Permiso
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.permiso"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center p-3">
                        Código
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.codigo"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center p-3">
                        Versión
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.version"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center p-3">
                        Fecha
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha"
                          @input="getIndex()"
                        />
                      </th>
                      <th class="text-center p-3">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(informe, index) in informes.data" :key="index">
                      <td class="text-center">{{ informe.nombre }}</td>
                      <td class="text-center">{{ informe.permiso }}</td>
                      <td class="text-center">{{ informe.codigo }}</td>
                      <td class="text-center">{{ informe.version }}</td>
                      <td class="text-center">{{ informe.fecha }}</td>
                      <td class="text-center">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-gradient-navy"
                            @click="editInforme(informe)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.configuracionInformes.edit'
                              )
                            "
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-gradient-danger"
                            @click="destroyInforme(informe)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.configuracionInformes.delete'
                              )
                            "
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="informes.total">
                  Mostrando del <b>{{ informes.from }}</b> al
                  <b>{{ informes.to }}</b> de un total:
                  <b>{{ informes.total }}</b> Registros
                </div>
                <div class="float-left" v-else>
                  <span class="badge badge-danger">
                    No hay registros para mostrar
                  </span>
                </div>
                <pagination
                  :data="informes"
                  @pagination-change-page="getIndex"
                  :limit="10"
                  class="float-right m-0"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";

export default {
  name: "ConfiguracionInformeIndex",
  components: {
    Loading,
    pagination,
  },

  data() {
    return {
      cargando: false,
      filtros: {
        nombre: null,
        permiso: null,
        codigo: null,
        version: null,
        fecha: null,
      },
      informes: {},
      usuario_id: this.$store.getters.getUser.id,
    };
  },
  methods: {
    /* Función Inicial */
    async getIndex(page = 1) {
      this.cargando = true;
      await axios
        .get("/api/hidrocarburos/configuracionInformes?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.informes = response.data;
        });
    },

    /* Función Limpiar Filtros */
    async limpiarFiltros() {
      this.filtros = {
        nombre: null,
        permiso: null,
        codigo: null,
        version: null,
        fecha: null,
      };
      await this.getIndex();
    },

    /* Función Formulario Crear */
    createInforme() {
      return this.$router.push({
        name: "/Hidrocarburos/ConfiguracionInformeForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    /* Función Formulario Editar */
    editInforme(informe) {
      return this.$router.push({
        name: "/Hidrocarburos/ConfiguracionInformeForm",
        params: {
          accion: "Editar",
          data_edit: informe,
          id: informe.id,
        },
      });
    },

    /* Función Eliminar Informe */
    destroyInforme(informe) {
      this.$swal({
        title: "Esta seguro de eliminar la configuración del informe ?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios
            .delete("/api/hidrocarburos/configuracionInformes/" + informe.id)
            .then(() => {
              this.cargando = false;
              this.$swal({
                icon: "success",
                title: "Se eliminó el registro exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getIndex();
            });
        }
      });
    },
  },
  async mounted() {
    await this.getIndex();
  },
};
</script>
